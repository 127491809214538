import { Avatar, Badge, List } from "antd";
import React from "react";
import moment from "moment";
import {
  DeleteTwoTone,
  ClockCircleOutlined,
  UserAddOutlined,
  CheckCircleOutlined,
} from "@ant-design/icons";
import { EmptyData } from "../../Common/components/EmptyData";
import { FaBirthdayCake } from "react-icons/fa";
import { useMediaQuery } from "react-responsive";
import styles from "../style/landingPage.module.css";

const ScheduleList = ({
  currentScheduleData,
  setSelectedClient,
  showDeleteConfirm,
  handleListItemClick,
  section,
  setTypeOfCreation,
  currentReminderData
}) => {
  const isMobile = useMediaQuery({ maxWidth: 520 });
  const isTab = useMediaQuery({ minWidth:579,maxWidth:1200 });
  const mediumScreen = useMediaQuery({ minWidth:521,maxWidth:768 });

  return (
    <>
      {currentScheduleData.length > 0 && (
        <List
          itemLayout="horizontal"
          dataSource={currentScheduleData}
          renderItem={(item) => (
            <List.Item
              style={{
                display: (isMobile || section === "HOME") ? "block" : "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
              actions={[
                <div style={{display:"flex",gap:"20px"}}>
                <h4
                  onClick={() => handleListItemClick(item,"schedule")}
                  style={{
                    width: "100%",
                    fontSize:(isMobile || section === "HOME") ? "13px" : "16px",
                    color: "black",
                    display: "flex",
                    justifyContent: "center",
                    backgroundColor: "lightgray",
                    borderRadius: "15px",
                    padding: "5px",
                    marginLeft: mediumScreen && section === "HOME"
                    ? "40px"
                    : section === "HOME" && !isMobile
                      ? "17px"
                      : isMobile || section === "HOME"
                        ? "50px"
                        : ""
                  }}
                >
                  <ClockCircleOutlined
                    style={{ marginTop: (isMobile || section === "HOME") ? "3px" : "5px", marginRight: "5px" }}
                  />{" "}
                  {moment(item.startTimestamp).format("LT")} -{" "}
                  {moment(item.endTimestamp).format("LT")}
                </h4>
                {(!isMobile && section !== "HOME") && <div>
                  {item.type === "SCHEDULE" || item.type === "CONTRACT_SCHEDULE" || item.type === "CLIENT_EVENT" ? (
                    <DeleteTwoTone
                      style={{
                        color: "black",
                        fontSize: (isMobile && section !== "HOME") ? "22px" : 16,
                        marginTop: (isMobile && section !== "HOME") ? "4px" : "8px"
                      }}
                      onClick={(e) => {
                        e.stopPropagation();
                        showDeleteConfirm(item,"schedule")
                      }}
                    />
                  ) : null}
                </div>}
                </div>
              ]}
            >
              <List.Item.Meta
                onClick={() => {
                  setTypeOfCreation(true);
                  handleListItemClick(item,"schedule")
                }}
                avatar={
                  item.type === "BIRTHDAY" ? (
                    item?.profileImg?.original ? (
                      <Avatar
                        size={52}
                        src={item?.profileImg?.original}
                      />
                    ) : item?.firstName ? (
                      <Avatar size={52} style={{ background: "#085191" }}>
                        {(item?.firstName
                          ? item?.firstName[0]?.toUpperCase()
                          : "") +
                          "" +
                          (item?.lastName
                            ? item?.lastName[0]?.toUpperCase()
                            : "")}
                      </Avatar>
                    ) : (
                      <Avatar
                        shape="circle"
                        size={52}
                        style={{
                          background: "#085191",
                          // fontSize: "24px",
                          display: "flex",
                          alignItems: "center",
                          // height: "52px",
                        }}
                      >
                        <UserAddOutlined
                          style={{
                            // fontSize: "24px",
                            fill: "#085191",
                            color: "white",
                          }}
                        />
                      </Avatar>
                    )
                  ) : item?.taggedPersonIds?.[0]?.profileImg?.original ? (
                    <Avatar
                      size={52}
                      src={item?.taggedPersonIds?.[0]?.profileImg?.original}
                    />
                  ) : item?.taggedPersonIds?.[0]?.firstName ? (
                    <Avatar size={52} style={{ background: "#085191" }}>
                      {(item?.taggedPersonIds?.[0]?.firstName
                        ? item?.taggedPersonIds?.[0]?.firstName?.[0]?.toUpperCase()
                        : "") +
                        "" +
                        (item?.taggedPersonIds?.[0]?.lastName
                          ? item?.taggedPersonIds?.[0]?.lastName?.[0]?.toUpperCase()
                          : "")}
                    </Avatar>
                  ) : (
                    <Avatar
                      shape="circle"
                      // onClick={() => setShowClientList(!showClientList)}
                      size={52}
                      style={{
                        background: "#085191",
                        cursor: "pointer",
                        // fontSize: "24px",
                        display: "flex",
                        alignItems: "center",
                        // height: "52px",
                      }}
                    >
                      <UserAddOutlined
                        style={{
                          // fontSize: "24px",
                          fill: "#085191",
                          color: "white",
                        }}
                      />
                    </Avatar>
                  )
                }
                title={
                  <div style={(isMobile || section === "HOME") ? { display: "flex", justifyContent: "space-between", alignItems: "center", width: "100%" } : {}}>
                    <div style={{
                      fontSize: "18px", width: "100%", whiteSpace: "pre-wrap",
                      overflow: "hidden",
                    }} className={styles.singleTextWraping}>
                      <span style={{ display: "inline-block"}}>
                        {item.type === "BIRTHDAY"
                          ? item?.fullName
                          : item?.title || item.address}
                        {item?.completedDateMili && (
                          <CheckCircleOutlined
                            style={{
                              color: "green",
                              fontSize: "12px",
                              marginLeft: "4px",
                              verticalAlign: "0.2em"
                            }}
                          />
                        )}
                      </span>
                    </div>
                    {(isMobile || section === "HOME") && <div>
                      {item.type === "SCHEDULE" || item.type === "CONTRACT_SCHEDULE" ? (
                        <DeleteTwoTone
                          style={{
                            color: "black",
                            fontSize: (isMobile && section !== "HOME") ? "16px" : 16,
                            marginTop: (isMobile && section !== "HOME") ? "4px" : "8px",
                            marginLeft: (isMobile || section === "HOME") ? "-23px" : ""
                          }}
                          onClick={(e) => {
                            e.stopPropagation();
                            showDeleteConfirm(item, "schedule")
                          }}
                        />
                      ) : null}
                    </div>}
                  </div>
                }
                description={
                  <>
                    <h6 style={{ fontSize: "14px", color: "#8C8C8C" }}>
                    <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap:"10px",
                     
                    }}
                    className={styles.singleTextWraping}
                  >
                    {item.type === "BIRTHDAY" ? (
                    <>
                      <div>
                        <FaBirthdayCake style={{ fill:"#000000"}} />
                      </div>
                      <div
                        style={{
                          marginTop: "-30px",
                          display: isTab ? "block" : "flex",
                          alignItems: "center",
                          marginLeft: "90px",
                        }}
                      >
                        <Badge
                          count={"Birthday"}
                          style={{ marginBottom:"7px" ,color:"black",background:"lightgreen"}}
                        />{" "}
                      </div>
                    </>
                   ) : (
                    <div style={{ whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          width: "100%",}} className={styles.singleTextWraping}>
                    {item.description || item.name}
                    </div>
                     )} 
                  </div>
                   
                    </h6>
                    <p style={{ fontSize: "14px" }}>
                      Date :{" "}
                      {` ${moment(item?.startTimestamp).format(
                        "dddd"
                      )}, ${moment(item?.startTimestamp)
                        .format("L")
                        .replace("/", "-")
                        .replace("/", "-")} at ${moment(item?.startTimestamp).format("hh:mm A")}`}
                    </p>
                  </>
                }
              />
            </List.Item>
          )}
        />
      ) 
      // : (
      //   section !== "HOME" && !currentReminderData?.length &&
      //   <div style={{ marginTop: "10%" }}>
      //     <EmptyData />
      //   </div>
      // )}
      }
    </>
  );
};

export default ScheduleList;
