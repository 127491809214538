import React from "react";
import { Table } from "antd";

const BosTable = () => {
    const columnStyle = {
        color:"white",
        fontWeight:"bold"
    }

  const columns = [
    { title: <span style={columnStyle}>Name</span> , dataIndex: "col1", key: "col1" },
    { title: <span style={columnStyle}>Brokerage</span> , dataIndex: "col2", key: "col2" },
    { title: <span style={columnStyle}>Status</span> , dataIndex: "col3", key: "col3" },
    { title: <span style={columnStyle}>Category</span> , dataIndex: "col4", key: "col4" },
    { title: <span style={columnStyle}>Created On</span>, dataIndex: "col5", key: "col5" },
  ];

  const data = [
    { key: "1", col1: "Data 1", col2: "Data 2", col3: "Data 3", col4: "Data 4", col5: "Data 5" },
    { key: "2", col1: "Data A", col2: "Data B", col3: "Data C", col4: "Data D", col5: "Data E" },
  ];

  return <Table columns={columns} dataSource={data} pagination={false} />;
};

export { BosTable };
