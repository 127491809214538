import { Button, Modal, Tree } from "antd";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { appStatusChange } from "../state/actions";

const BOSModal = ({ openModal, setOpenModal, hasInitialized, checkedKeys, setCheckedKeys, transformDataToTree }) => {
  // const [checkedKeys, setCheckedKeys] = useState([]);
  const [brokerageGroupName, setBrokerageGroupName] = useState("")
  const [firmIds,setFirmIds] = useState([]);

  const { getUserDetailsData } = useSelector((state) => state.account);
  const treeData = transformDataToTree(getUserDetailsData?.allBackOfficeData);

  const dispatch = useDispatch();
  const history = useHistory();

  const getAllKeys = (data) => {
    let keys = [];
    data.forEach((item) => {
      keys.push(item?.key);
      if (item?.children?.length) {
        keys = [...keys, ...getAllKeys(item?.children)];
      }
    });
    return keys;
  };

  const expandedKeys = getAllKeys(treeData);

  const findParentItem = (key, data) => {
    for (const item of data) {
      if (item?.key === key) return item;
      if (item?.children?.length) {
        const foundItem = findParentItem(key, item?.children);
        if (foundItem) return item;
      }
    }
    return null;
  };

  const getFirstParentItem = (data) => {
    return data?.length ? data[0] : null;
  };

  // const onCheck = (checkedKeysValue, { node }) => {
  //   const parentItem = findParentItem(node?.key, treeData);

  //   setCheckedKeys((prevCheckedKeys) => {
  //     const filteredKeys = prevCheckedKeys?.filter(
  //       (key) => findParentItem(key, treeData)?.key === parentItem?.key
  //     );

  //     const newCheckedKeys = filteredKeys?.includes(node?.key)
  //       ? filteredKeys?.filter((key) => key !== node?.key)
  //       : [...filteredKeys, node?.key];

  //     const selectedParentItem = findParentItem(newCheckedKeys[0], treeData);
  //     setBrokerageGroupName(selectedParentItem?.name || "");

  //     const childKeys = newCheckedKeys?.filter(
  //       (key) => !treeData?.some((item) => item.key === key)
  //     );
  //       setFirmIds(childKeys)
  //     // dispatch(appStatusChange.updateBosFirmIds( childKeys ));
  //     return newCheckedKeys;
  //   });
  // };

  const onCheck = (checkedKeysValue, { node }) => {
    const parentItem = findParentItem(node?.key, treeData);
  
    setCheckedKeys((prevCheckedKeys) => {
      const filteredKeys = prevCheckedKeys?.filter(
        (key) => findParentItem(key, treeData)?.key === parentItem?.key
      );
  
      let newCheckedKeys;
      
      const getChildKeys = (key, data) => {
        let childNodes = data?.find(item => item.key === key)?.children || [];
        return childNodes.reduce(
          (acc, child) => [...acc, child.key, ...getChildKeys(child.key, data)], 
          []
        );
      };
  
      const childKeys = getChildKeys(node?.key, treeData);
  
      if (filteredKeys.includes(node?.key)) {
        newCheckedKeys = filteredKeys.filter(
          (key) => key !== node?.key && !childKeys.includes(key)
        );
      } else {
        newCheckedKeys = [...filteredKeys, node?.key, ...childKeys];
      }
  
      const selectedParentItem = findParentItem(newCheckedKeys[0], treeData);
      setBrokerageGroupName(selectedParentItem?.name || "");
  
      setFirmIds(newCheckedKeys?.filter(
        (key) => !treeData.some((item) => item.key === key)
      ));
  
      return newCheckedKeys;
    });
  };
  

  const handleCancel = () => {
    setOpenModal({ ...openModal, isModalOpen: false });
  };

  const handleBos = () => {
    setOpenModal({ ...openModal, isModalOpen: false, brokerageGroupName: brokerageGroupName })
    history.push("/contacts")
    dispatch(appStatusChange.updateBosFirmIds(firmIds));
  };

  // useEffect(() => {
  //   if (!hasInitialized.current) {
  //     const firstParentItem = getFirstParentItem(treeData);
  //     if (firstParentItem) {
  //       setCheckedKeys([firstParentItem?.key]);
  //       appStatusChange.updateBosFirmIds({id:[firstParentItem?.key]})
  //       // setBrokerageGroupName(firstParentItem?.name || "");
  //     }
  //     hasInitialized.current = true;
  //   }
  // }, []);

  useEffect(() => {
    if (!hasInitialized.current) {
      const firstParentItem = getFirstParentItem(treeData);
      if (firstParentItem) {
        const getChildKeys = (data) => {
          let keys = [];
          data.forEach((item) => {
            keys.push(item.key);
            if (item.children?.length) {
              keys = [...keys, ...getChildKeys(item.children)];
            }
          });
          return keys;
        };
        const allChildKeys = getChildKeys(firstParentItem.children);
        const allSelectedKeys = [...allChildKeys];
  
        setCheckedKeys(allSelectedKeys);
        setFirmIds(allSelectedKeys)
        // dispatch(appStatusChange.updateBosFirmIds(allSelectedKeys));
      }
      hasInitialized.current = true;
    }
  }, [treeData]);
  

  return (
    <Modal
      open={openModal}
      title={<div className="commonModalTitle">BOS</div>}
      onCancel={handleCancel}
      footer={false}
    >
      <div>
        <Tree
          defaultExpandedKeys={expandedKeys}
          checkable
          treeData={treeData}
          checkedKeys={checkedKeys}
          onCheck={onCheck}
        />
        <div style={{ display: "flex", justifyContent: "flex-end", gap: "10px" }}>
          <Button onClick={handleCancel}>Cancel</Button>
          <Button type="primary" onClick={handleBos} disabled={!checkedKeys?.length}>
            Save
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default BOSModal;