import React from "react";
import { Checkbox, Dropdown, Tooltip } from "antd";
import { FileImageOutlined, FileTextOutlined, MoreOutlined } from "@ant-design/icons";
import moment from "moment";
import { FaRegFilePdf } from "react-icons/fa";
import { conterOfferContractTypes, createOfferContractTypes } from "../../Common/commondata/commonData";
import ProperyDocMenuItem from "./ProperyDocMenuItem";
import { hasPropertyDocsMenuItems } from "../utils/helper";

const SubFolderFile = ({ eachDoc, parentFolderId, level, role, getCoordinateItem, handlePropertyDocs, handleShareUnshareFileConfirm, handleToggleDashboard, handleDeletePropertyDocs, isSmallScreen, selectedFiles, hoveredRow, setHoveredRow, handleSelectFile, handleDragStart, handleCreateAmendExtend }) => {
  return (
    <>
      <tr key={eachDoc?._id} draggable={!eachDoc?.isCustomerDoc} onDragStart={!eachDoc?.isCustomerDoc ? (e) => handleDragStart(e, { ...eachDoc, parentFolderId }) : undefined}>
        <td
          onClick={() => handlePropertyDocs(eachDoc)}
          style={{
            fontWeight: "bolder",
            color: "blue",
            minWidth: "250px",
            width: "65%",
            cursor: "pointer",
            border: "none",
            paddingLeft: `${level * 23}px`,
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              fontSize: isSmallScreen ? "13px" : "16px",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <div style={{ width: "26px", height: "26px" }} onMouseEnter={() => setHoveredRow(eachDoc?._id)} onMouseLeave={() => setHoveredRow(null)}>
                {!eachDoc.isCustomerDoc && (hoveredRow === eachDoc?._id || selectedFiles?.propertyDocs?.filter((el) => el?.propertyDocId === eachDoc?._id)?.length || selectedFiles?.offerDocs?.filter((el) => el?.offerDocId === eachDoc?._id)?.length) ? (
                  <Checkbox
                    checked={eachDoc?.contractId ? selectedFiles?.offerDocs?.filter((el) => el?.offerDocId === eachDoc?._id)?.length : selectedFiles?.propertyDocs?.filter((el) => el?.propertyDocId === eachDoc?._id)?.length}
                    onClick={(e) => {
                      e.stopPropagation();
                      handleSelectFile(eachDoc?._id, eachDoc?.contractId ? true : false, parentFolderId);
                    }}
                    style={{ marginRight: "4px" }}
                  />
                ) : (
                  <>
                    {eachDoc?.fileType === "IMAGE" ? (
                      <FileImageOutlined
                        style={{
                          color: "#ecd540",
                          marginTop: "5px",
                          marginRight: "5px",
                          fontSize: "18px",
                        }}
                      />
                    ) : eachDoc?.fileType === "PDF" || eachDoc?.isPdf ? (
                      <FaRegFilePdf
                        style={{
                          color: "red",
                          marginRight: "5px",
                          fontSize: "18px",
                        }}
                      />
                    ) : eachDoc?.offerDocument && eachDoc?.contractType === "LCAES" ? (
                      <FileTextOutlined
                        style={{
                          color: "#74b3ed",
                          marginRight: "5px",
                          fontSize: "18px",
                        }}
                      />
                    ) : (
                      <FileTextOutlined
                        style={{
                          color: "#74b3ed",
                          marginRight: "5px",
                          fontSize: "18px",
                        }}
                      />
                    )}
                  </>
                )}
              </div>
              <span>
                {eachDoc?.documentName || eachDoc?.fileName || ""}
                {eachDoc?.offerDocument && !eachDoc?.offerId && !["ERTS", "LCAES", "FRASCONA_ERTS"].includes(eachDoc?.contractType) && <span style={{ color: "grey", fontSize: "12px" }}>{" (Without Buyers)"}</span>}
              </span>
            </div>
            {eachDoc?.transactionData && (
              <div
                style={{
                  color: "grey",
                  fontSize: "11px",
                  marginLeft: "24px",
                }}
              >
                {["ERTS", "FRASCONA_ERTS"].includes(eachDoc?.contractType) && eachDoc?.transactionData && eachDoc?.transactionData?.ListingPeriodBeginDate && <span>{moment(eachDoc?.transactionData?.ListingPeriodBeginDate).format("MM/DD/YYYY")}</span>}
                {["ERTS", "FRASCONA_ERTS"].includes(eachDoc?.contractType) && eachDoc?.transactionData && eachDoc?.transactionData?.ListingPeriodEndByDate && <> - {moment(eachDoc?.transactionData?.ListingPeriodEndByDate).format("MM/DD/YYYY")}</>}
                <span style={{ marginLeft: "19px" }}>
                  {eachDoc?.contractType === "LCAES" && eachDoc?.transactionData && eachDoc?.transactionData?.ListingPeriodEndByDate && <span>Ending Date </span>}
                  {eachDoc?.contractType === "LCAES" && eachDoc?.transactionData && eachDoc?.transactionData?.ListingPeriodEndByDate && <span> - {moment(eachDoc?.transactionData?.ListingPeriodEndByDate).format("MM/DD/YYYY")}</span>}
                </span>
              </div>
            )}
            <div
              style={{
                color: "grey",
                fontSize: "11px",
                marginLeft: "28px",
              }}
            >
              {eachDoc?.fileType ? `Uploaded by: ${eachDoc?.personId?.fullName}` : ""}
            </div>
          </div>
        </td>
        <td
          style={{
            color: "black",
            fontWeight: "500",
            textAlign: "center",
            minWidth: "50px",
            width: level === 1 ? "200px" : "170px",
            whiteSpace: "nowrap",
            border: "none",
            overflow: "hidden",
          }}
        >
          <div>{moment(eachDoc?.createdDateMili).format("MM/DD/YYYY")}</div>
          <div>{moment(eachDoc?.createdDateMili).format("hh:mm A")}</div>
        </td>
        <td
          style={{
            fontWeight: "bolder",
            textAlign: "center",
            minWidth: "70px",
            width: "10%",
            color: "#085191",
            border: "none",
          }}
        >
          {!eachDoc?.contractId ? (
            role === "SELLER" && getCoordinateItem?.offerId ? (
              <Checkbox
                checked={eachDoc?.isShared}
                onChange={(e) => {
                  handleShareUnshareFileConfirm(eachDoc, e.target.checked);
                }}
                style={{
                  color: eachDoc?.isShared ? "white" : "black",
                  borderRadius: "5px",
                  cursor: "pointer",
                }}
              />
            ) : role === "BUYER" && eachDoc?.showSeller ? (
              <Checkbox
                checked={eachDoc?.isShared}
                onChange={(e) => {
                  handleShareUnshareFileConfirm(eachDoc, e.target.checked);
                }}
                style={{
                  color: eachDoc?.isShared ? "white" : "black",
                  borderRadius: "5px",
                  cursor: "pointer",
                }}
              />
            ) : (
              <Tooltip title={role === "SELLER" && !getCoordinateItem?.offerId ? "Cannot share: Accepted offer does not exist." : role === "BUYER" && !eachDoc?.showSeller ? "Please send this offer to seller agent to enable sharing." : ""} placement="right" style={{ width: "50px" }}>
                <Checkbox
                  checked={false}
                  disabled={true}
                  style={{
                    color: "black",
                    borderRadius: "5px",
                    cursor: "not-allowed",
                  }}
                />
              </Tooltip>
            )
          ) : (
            <div />
          )}
        </td>
        <td
          style={{
            textAlign: "center",
            minWidth: "100px",
            width: "15%",
            border: "none",
          }}
        >
          {eachDoc?.offerId && eachDoc?.offerDocument && eachDoc?.contractId && !["ERTS", "FRASCONA_ERTS", "NET_SHEET"].includes(eachDoc?.contractType) && (
            <Checkbox
              checked={eachDoc?.isCreatedInRtd}
              onChange={(e) => handleToggleDashboard(eachDoc, e.target.checked)}
              disabled={(createOfferContractTypes?.includes(eachDoc?.contractType) && getCoordinateItem?.status === "ACCEPTED") || (conterOfferContractTypes?.includes(eachDoc?.contractType) && getCoordinateItem?.propertyStatus === "ACCEPTED")}
              style={{
                color: "black",
                borderRadius: "5px",
                cursor: "pointer",
              }}
            />
          )}
        </td>

        <td
          style={{
            textAlign: "center",
            minWidth: "70px",
            width: "10%",
            color: "#085191",
            border: "none",
          }}
        >
          {hasPropertyDocsMenuItems({ eachDoc, getCoordinateItem }) ? (
            <Dropdown overlay={<ProperyDocMenuItem parentDoc={eachDoc} eachDoc={eachDoc} getCoordinateItem={getCoordinateItem} handleDeletePropertyDocs={handleDeletePropertyDocs} handleCreateAmendExtend={handleCreateAmendExtend} />} trigger={["hover", "click"]}>
              <MoreOutlined
                key="ellipsis"
                style={{
                  fontSize: "26px",
                  color: "#08c",
                  marginRight: "-15px",
                  paddingTop: "5px",
                }}
              />
            </Dropdown>
          ) : (
            <div />
          )}
        </td>
      </tr>
      {eachDoc?.matchedDocuments?.map((matchedDoc) => (
        <React.Fragment key={matchedDoc?._id}>
          <hr
            style={{
              // width: "100%",
              width: level === 1 ? "calc(100% - 35px)" : "calc(100% - 40px)",
              color: "#BFBFBF",
              margin: level === 1 ? "0 0 0 30px" : "0 0 0 35px",
              border: "none",
              borderTop: "1px solid #BFBFBF",
            }}
          />
          <tr key={matchedDoc?._id} style={{ borderLeft: "none", borderRight: "none" }}>
            <td
              style={{
                fontWeight: "bolder",
                color: "blue",
                minWidth: "250px",
                cursor: "pointer",
                width: "65%",
                paddingLeft: `${(level + 1) * 23}px`,
                border: "none",
              }}
              onClick={() => handlePropertyDocs(matchedDoc)}
            >
              <div style={{ display: "flex", alignItems: "center" }}>
                <FileTextOutlined
                  style={{
                    color: "#74b3ed",
                    marginRight: "5px",
                    fontSize: "18px",
                  }}
                />
                <span>{matchedDoc?.documentName || matchedDoc?.fileName}</span>
              </div>
              {matchedDoc.transactionData?.ListingPeriodEndByDate && (
                <div
                  style={{
                    color: "grey",
                    fontSize: "11px",
                    marginLeft: "5px",
                  }}
                >
                  <span style={{ marginLeft: "19px" }}>
                    {<span>Ending Date </span>}
                    {<span> - {moment(matchedDoc.transactionData.ListingPeriodEndByDate).format("MM/DD/YYYY")}</span>}
                  </span>
                </div>
              )}
            </td>

            <td style={{ color: "black", fontWeight: "500", textAlign: "center", minWidth: "50px", width: "170px", whiteSpace: "nowrap", border: "none" }}>
              <div>{moment(matchedDoc?.createdDateMili).format("MM/DD/YYYY")}</div>
              <div>{moment(matchedDoc?.createdDateMili).format("hh:mm A")}</div>
            </td>
            <td style={{ textAlign: "center", minWidth: "70px", width: "10%", border: "none" }}>
              <div></div>
            </td>
            <td
              style={{
                textAlign: "center",
                minWidth: "100px",
                width: "15%",
                border: "none",
              }}
            >
              <div></div>
            </td>
            <td style={{ textAlign: "center", minWidth: "70px", width: "10%", border: "none" }}>
              {hasPropertyDocsMenuItems({ eachDoc, getCoordinateItem }) ? (
                <Dropdown overlay={<ProperyDocMenuItem parentDoc={eachDoc} eachDoc={matchedDoc} getCoordinateItem={getCoordinateItem} handleCreateAmendExtend={handleCreateAmendExtend} handleDeletePropertyDocs={handleDeletePropertyDocs} />} trigger={["hover", "click"]}>
                  <MoreOutlined
                    key="ellipsis"
                    style={{
                      fontSize: "26px",
                      color: "#08c",
                      marginRight: "-15px",
                      paddingTop: "5px",
                    }}
                  />
                </Dropdown>
              ) : (
                <div />
              )}
            </td>
          </tr>
        </React.Fragment>
      ))}
    </>
  );
};

export default SubFolderFile;
