import axios from "axios";
import dotEnv from "dotenv";
dotEnv.config();

const apiURL = process.env.REACT_APP_BASE_URL;

const templateDataRequest = ({contractType, search}) => {
  let accessToken = localStorage.getItem("userAuth");
  return axios.get(`${apiURL}/templates`, {
    params: {
      ...(contractType ? { contractType } : {}),
      ...(search ? { search } : {}),
    },
    headers: {
      Accept: "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
  });
};
const getContractDocumentariesRequest = () => {
  let accessToken = localStorage.getItem("userAuth");
  return axios.get(`${apiURL}/getContractDocumentaries`, {
    headers: {
      Accept: "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
  });
};

const editemplateDataRequest = (payload) => {
  let accessTokan = localStorage.getItem("userAuth");
  return axios.put(
    `${apiURL}/template`,
    {
      ...payload,
    },
    {
      headers: {
        accept: "application/json",
        authorization: `bearer ${accessTokan}`,
      },
    }
  );
};

const deleteTemplateRequest = (id) => {
  let accessTokan = localStorage.getItem("userAuth");
  return axios.delete(`${apiURL}/templates/${id}`, {
    headers: {
      accept: "application/json",
      authorization: `bearer ${accessTokan}`,
    },
  });
};

const templateShortening = (data) => {
  let accessTokan = localStorage.getItem("userAuth");
  return axios.post(
    `${apiURL}/url/builtForSection`,
    {
      ...data,
    },
    {
      headers: {
        accept: "application/json",
        authorization: `bearer ${accessTokan}`,
      },
    }
  );
};

export const templateDataApi = {
  templateDataRequest,
  deleteTemplateRequest,
  templateShortening,
  editemplateDataRequest,
  getContractDocumentariesRequest
};
